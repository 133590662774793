import React, { useState, useContext } from 'react';
import { TextField, Button, Typography, Container, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import AuthContext from '../context/AuthContext';
import { supabase } from '../supabaseClient';

const Login = () => {
  const { setUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }

    setError('');
    setLoading(true);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        console.error('Login error', error);
        setError(error.message);
      } else {
        const { user } = data;
        setUser(user);
        console.log('User logged in', user);
      }
    } catch (error) {
      console.error('Unexpected login error:', error);
      setError('An unexpected error occurred. Please try again.');
    }

    setLoading(false);
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Typography variant="h6">Login</Typography>
      <TextField
        label="Email"
        type="email"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleLogin}
        style={{ marginTop: '1rem' }}
        disabled={loading}
      >
        Log In
      </Button>
      {error && (
        <Typography color="error" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      )}
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        Don't have an account?{' '}
        <Link component="button" variant="body2" onClick={() => navigate('/register')}>
          Sign Up
        </Link>
      </Typography>
    </Container>
  );
};

export default Login;
