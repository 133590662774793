import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { Button } from '@mui/material';
import { supabase } from '../supabaseClient'; // Ensure supabase is imported

const Talk = () => {
  const { user } = useContext(AuthContext);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');
  const audioRef = useRef(null);
  const [error, setError] = useState('');

  useEffect(() => {
    async function requestMicrophoneAccess() {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (error) {
        console.error('Failed to get microphone access:', error);
        setError('Failed to access microphone. Please check your settings.');
      }
    }

    requestMicrophoneAccess();
  }, []);

  const handleStartRecording = async () => {
    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let chunks = [];

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });
        const { data } = await supabase.auth.getSession();
        const userId = data.session?.user?.id;
        const timestamp = Date.now();
        const uniqueFilename = `audio_${userId}_${timestamp}.wav`;

        const formData = new FormData();
        formData.append('audio', audioBlob, uniqueFilename);

        try {
          const token = data.session?.access_token;

          if (!token) {
            throw new Error('User is not authenticated');
          }

          console.log('Uploading audio...');
          const response = await axios.post('https://api.ask-ellie.com/assistant/process-audio/', formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',
          });

          console.log('Audio uploaded, processing...');
          const url = URL.createObjectURL(response.data);
          console.log('Audio URL:', url);
          setAudioUrl(url);
        } catch (error) {
          console.error('Upload failed:', error);
          setAudioUrl('');
        }
      };

      recorder.start();
      setRecording(true);
      setMediaRecorder(recorder);
    } catch (error) {
      console.error('Failed to start recording:', error);
      setError('Failed to access microphone. Please ensure it is not being used by another application.');
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" fullWidth onPointerDown={handleStartRecording} onPointerUp={handleStopRecording}>
        {recording ? 'Release to Stop' : 'Press to Talk'}
      </Button>
      {audioUrl && (
        <audio ref={audioRef} src={audioUrl} controls autoPlay style={{ marginTop: '1rem', width: '100%' }} />
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default Talk;