import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { TextField, Button, Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const defaultPrompt = "Your name is Ellie, a helpful AI companion. Remember to always be kind and respectful.";

const Settings = () => {
    const { user } = useContext(AuthContext);

    // State Declarations
    const [systemPrompt, setSystemPrompt] = useState('');
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState('');
    const [newAssistantName, setNewAssistantName] = useState('');
    const [newAssistantPrompt, setNewAssistantPrompt] = useState(defaultPrompt);
    const [userName, setUserName] = useState('');
    const [userBirthDate, setUserBirthDate] = useState('');
    const [userGender, setUserGender] = useState('');
    const [tools, setTools] = useState([]);
    const [selectedTools, setSelectedTools] = useState([]);
    const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
    const [recordingMode, setRecordingMode] = useState('push_to_talk');  // Moved up here

    // Functions
    const fetchSettings = async () => {
        if (user) {
            try {
                const { data } = await supabase.auth.getSession();
                const token = data.session?.access_token;

                const settingsResponse = await axios.get('https://api.ask-ellie.com/interactions/interaction-settings/', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setSystemPrompt(settingsResponse.data.system_prompt);

                await fetchAssistants(); // Fetch the assistants list

                const toolsResponse = await axios.get('https://api.ask-ellie.com/assistant/list_tools/', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setTools(toolsResponse.data.tools);

                try {
                    const selectionResponse = await axios.get('https://api.ask-ellie.com/assistant/get_selected_assistant/', {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });
                    setSelectedAssistant(selectionResponse.data.assistant_id);
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        console.log('No assistant selected yet.');
                        setSelectedAssistant('');
                    } else {
                        console.error('Error fetching selected assistant', error);
                    }
                }
            } catch (error) {
                console.error('Error fetching settings', error);
            }
        }
    };

    const fetchButtonSettings = async () => {
        if (user) {
            try {
                const { data } = await supabase.auth.getSession();
                const token = data.session?.access_token;

                const response = await axios.get('https://api.ask-ellie.com/button/button-settings/', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setRecordingMode(response.data.recording_mode);
            } catch (error) {
                console.error('Error fetching button settings', error);
                setRecordingMode('push_to_talk');  // Default
            }
        }
    };

    // Fetch settings when the component mounts or when the user changes
    useEffect(() => {
        fetchSettings();
        fetchButtonSettings();
    }, [user]);

    const updateButtonSettings = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post('https://api.ask-ellie.com/button/button-settings/', {
                recording_mode: recordingMode,
            }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            alert('Recording mode updated successfully');
        } catch (error) {
            console.error('Error updating recording mode', error);
            alert('Error updating recording mode');
        }
    };

    const fetchAssistants = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            const assistantsResponse = await axios.get('https://api.ask-ellie.com/assistant/list_assistants_with_tools/', {
                headers: { 'Authorization': `Bearer ${token}` },
            });
    
            console.log('Assistants list after deletion:', assistantsResponse.data);
            setAssistants(assistantsResponse.data);
        } catch (error) {
            console.error('Error fetching assistants', error);
        }
    };
    
    

    const updateSystemPrompt = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post('https://api.ask-ellie.com/interactions/interaction-settings/', { system_prompt: systemPrompt }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            alert('Settings updated successfully');
        } catch (error) {
            console.error('Error updating settings', error);
            alert('Error updating settings');
        }
    };

    const createNewAssistant = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;

            const response = await axios.post('https://api.ask-ellie.com/assistant/create_assistant_with_tools/', {
                assistant: {  
                    assistant_name: newAssistantName,
                    assistant_system_prompt: newAssistantPrompt,
                    user_name: userName,
                    birth_date: userBirthDate ? new Date(userBirthDate).toISOString().split('T')[0] : null,
                    gender: userGender,
                },
                tools: selectedTools
            }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            alert('Assistant created successfully');

            await fetchAssistants(); // Refetch assistants after creation

            // Clear the form
            setNewAssistantName('');
            setNewAssistantPrompt(defaultPrompt);
            setUserName('');
            setUserBirthDate('');
            setUserGender('');
            setSelectedTools([]);
        } catch (error) {
            console.error('Error creating assistant', error.response ? error.response.data : error);
            alert('Error creating assistant');
        }
    };    

    const selectAssistant = async (assistant_id) => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post('https://api.ask-ellie.com/assistant/select_assistant_with_tools/', { assistant_id }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setSelectedAssistant(assistant_id);
            await fetchAssistants(); // Refetch assistants after selection
            alert('Assistant selected successfully');
        } catch (error) {
            console.error('Error selecting assistant', error);
            alert('Error selecting assistant');
        }
    };

    const deleteAssistant = async (assistant_id) => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
    
            await axios.delete(`https://api.ask-ellie.com/assistant/delete_assistant/${assistant_id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
    
            // Filter out the deleted assistant from the state
            setAssistants(prevAssistants => prevAssistants.filter(assistant => assistant.assistant_id !== assistant_id));
    
            alert('Assistant deleted successfully');
        } catch (error) {
            console.error('Error deleting assistant', error);
            alert('Error deleting assistant');
        }
    };
    
    
    

    const toggleToolSelection = (tool) => {
        if (selectedTools.includes(tool)) {
            setSelectedTools(selectedTools.filter(t => t !== tool));
        } else {
            setSelectedTools([...selectedTools, tool]);
        }
    };

    // Render
    return (
        <Box p={3}>
            <Typography variant="h6" gutterBottom>
                Settings
            </Typography>
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Create Custom Assistant
                </Typography>
                <TextField
                    label="Assistant Name"
                    fullWidth
                    value={newAssistantName}
                    onChange={(e) => setNewAssistantName(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Assistant System Prompt"
                    multiline
                    rows={4}
                    fullWidth
                    value={newAssistantPrompt}
                    onChange={(e) => setNewAssistantPrompt(e.target.value)}
                    margin="normal"
                />
                <Typography variant="subtitle1" gutterBottom>
                    User Details (Optional)
                </Typography>
                <TextField
                    label="User's Name"
                    fullWidth
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="User's Birth Date"
                    type="date"
                    fullWidth
                    value={userBirthDate}
                    onChange={(e) => setUserBirthDate(e.target.value)}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="User's Gender"
                    fullWidth
                    value={userGender}
                    onChange={(e) => setUserGender(e.target.value)}
                    margin="normal"
                />
                <Typography variant="subtitle1" gutterBottom>
                    Select Tools (Optional)
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => setToolsMenuOpen(!toolsMenuOpen)}
                    style={{ marginTop: '1rem' }}
                >
                    {toolsMenuOpen ? 'Hide Tools' : 'Define Tools'}
                </Button>
                
                {toolsMenuOpen && (
                    <Box mt={2}>
                        {tools.map((tool) => (
                            <Button
                                key={tool}
                                variant={selectedTools.includes(tool) ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => toggleToolSelection(tool)}
                                style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                            >
                                {tool}
                            </Button>
                        ))}
                    </Box>
                )}

                <Button variant="contained" color="primary" fullWidth onClick={createNewAssistant} style={{ marginTop: '1rem' }}>
                    Create Assistant
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Select Custom Personality
                </Typography>
                <RadioGroup
                    aria-label="select-assistant"
                    value={selectedAssistant}
                    onChange={(e) => selectAssistant(e.target.value)}
                >
                    {assistants.map((assistant) => (
                        <FormControlLabel
                            key={assistant.assistant_id}  // Ensure this is a unique key
                            value={assistant.assistant_id}
                            control={<Radio />}
                            label={
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Typography variant="subtitle1">
                                            {assistant.assistant_name || 'Unnamed Assistant'}
                                        </Typography>
                                        <Typography variant="body2">
                                            {assistant.assistant_system_prompt}
                                        </Typography>
                                        <Typography variant="body2">
                                            Tools: {assistant.assistant_tools ? assistant.assistant_tools.join(', ') : 'None'}
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => deleteAssistant(assistant.assistant_id)}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <DeleteOutlineIcon />
                                    </Button>
                                </Box>
                            }
                        />
                    ))}

                </RadioGroup>
            </Box>
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Recording Mode
                </Typography>
                <RadioGroup
                    aria-label="recording-mode"
                    value={recordingMode}
                    onChange={(e) => setRecordingMode(e.target.value)}
                >
                    <FormControlLabel
                        value="push_to_talk"
                        control={<Radio />}
                        label="Push-to-Talk"
                    />
                    <FormControlLabel
                        value="toggle"
                        control={<Radio />}
                        label="Toggle"
                    />
                </RadioGroup>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={updateButtonSettings}
                    style={{ marginTop: '1rem' }}
                >
                    Update Recording Mode
                </Button>
            </Box>
        </Box>
    );
};

export default Settings;
