// src/components/Home.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const Home = () => {
  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Typography variant="h6" gutterBottom>
        Thank you for trying Ellie!
      </Typography>
      <Typography variant="body1">
        We are excited to deliver a screen-free AI companion device. Today, it can go on Wikipedia rabbit hole explorations with you and learn facts that you teach it. It can also keep track of ideas, questions, hypotheses, or even your mood from day to day. Just start by asking Ellie 'How do you feel?'
      </Typography>
    </Container>
  );
};

export default Home;
